import React, { ComponentProps, useEffect, useState } from 'react';
import styled, { keyframes } from 'styled-components';
import { PrimaryButton } from 'components';
import { tablet } from 'styles/breakpoints';

const Button = styled(PrimaryButton)<{
  isClicked: boolean;
  isVisible?: boolean;
}>`
  max-width: 28.75rem;
  width: 100%;
  transition: transform 0.1s ease-out, border-color 0.2s ease-in-out,
    background-color 0.2s ease-in-out;
  transform: ${({ isClicked }) => (isClicked ? 'scale(0.98)' : 'scale(1)')};

  margin: 0 auto;
`;

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

const Wrapper = styled.div<{
  removeBorder?: boolean;
  isNotSticky?: boolean;
  isVisible?: boolean;
}>`
  width: 100%;
  transition: transform 0.1s ease-in-out;
  @media ${tablet} {
    bottom: 0;
    left: 0;
    right: 0;
    min-height: 80px;
    width: 100%;
    transition: transform 0.1s ease-in-out, opacity 0.4s ease-out;
    opacity: ${({ isVisible }) => (isVisible ? 1 : 0)};
    animation: ${({ isVisible }) => (isVisible ? fadeIn : 'none')} 0.4s ease-out;
    ${({ isNotSticky }) =>
      !isNotSticky &&
      `
      position: fixed; 
      padding: 1rem 1rem;
      &::before {
      content: '';
      position: absolute;
      content: '';
      position: absolute;
      top: 0px;
      left: 0px;
      right: 0px;
      height: 80%;
      background: #fff;
      filter: blur(4px);
      z-index: -1;
    }
    &::after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      height: 80%;
      background: #fff;
      z-index: -1;
    }`}
  }
`;

const ContinueButton = (props: ComponentProps<typeof PrimaryButton>) => {
  const [isClicked, setIsClicked] = useState(false);
  const [isVisible, setIsVisible] = useState(false);
  const [isVisibleButton, setIsVisibleButton] = useState(false);

  const handleClick = () => {
    setIsClicked(true);
    setTimeout(() => setIsClicked(false), 150);
  };

  useEffect(() => {
    setTimeout(() => {
      setIsVisible(true);
    }, 600);
    setTimeout(() => {
      setIsVisibleButton(true);
    }, 600);
  }, []);
  return (
    <Wrapper
      onClick={handleClick}
      removeBorder={props?.removeBorder}
      isNotSticky={props?.isNotSticky}
      isVisible={isVisible}
    >
      <Button isClicked={isClicked} {...props} isVisible={isVisibleButton}>
        {props?.title ?? 'Continue'}
      </Button>
    </Wrapper>
  );
};

export default ContinueButton;
